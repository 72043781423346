import React from 'react';
import { useTranslation } from "react-i18next";

const HowItWork = () => {
  const { t, i18n } = useTranslation();

  const getLanguageSpecificImage = () => {
    switch (i18n.language) {
      case "en":
        return "/images/arbitrage/fund_eng.png";
      default:
        return "/images/arbitrage/fund_eng.png";
    }
  };

  return (
    <section className="about pos-rel pb-105 pb-md-65" style={{ overflow: "hidden" }}>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-5 sec-title style2 mb-45 wow fadeInLeft"
            data-wow-delay="100ms"
          >
            <h2 className="sec-title__title text-50 mb-25">
              {t("vault_how_it_work.title")}
            </h2>
          </div>
          <div
            className="col-lg-7 mb-45 wow fadeInRight"
            data-wow-delay="100ms"
          >
            <p className="text-20 text-white">
              {t("vault_how_it_work.description")}
            </p>
          </div>
        </div>
        <div
          className="row wow fadeInUp"
          data-wow-delay="200ms"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-xl-4 col-md-10 mb-20">
            <div
              className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4"
              style={{
                boxShadow: "0 0 8px rgba(0, 0, 0, 0.3)",
                height: "100%",
              }}
            >
              <div className="fugu--iconbox-thumb">
                <img src="assets/img/feature1.png" alt="" />
              </div>
              <div className="fugu--iconbox-data">
                <h4>{t("common.step")} 1</h4>
                <p>{t("vault_how_it_work.step_1")}</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-10 mb-20">
            <div
              className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4"
              style={{
                boxShadow: "0 0 8px rgba(0, 0, 0, 0.3)",
                height: "100%",
              }}
            >
              <div className="fugu--iconbox-thumb">
                <img src="assets/img/feature2.png" alt="" />
              </div>
              <div className="fugu--iconbox-data">
                <h4>{t("common.step")} 2</h4>
                <p>{t("vault_how_it_work.step_2")}</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-10 mb-20">
            <div
              className="fugu--iconbox-wrap fugu--iconbox-wrap3 fugu--iconbox-wrap4"
              style={{
                boxShadow: "0 0 8px rgba(0, 0, 0, 0.3)",
                height: "100%",
              }}
            >
              <div className="fugu--iconbox-thumb">
                <img src="assets/img/feature3.png" alt="" />
              </div>
              <div className="fugu--iconbox-data">
                <h4>{t("common.step")} 3</h4>
                <p>{t("vault_how_it_work.step_3")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          className="fugu--default-content content-black wow fadeInUp"
          data-wow-delay="300ms"
          style={{ marginTop: "50px", textAlign: "center" }}
        ></div>
        <div style={{ textAlign: "center" }}>
          <img
            src={getLanguageSpecificImage()}
            alt="fund"
            style={{ width: "96%", maxWidth: "900px" }}
          />
        </div>
      </div>

      <div className="faq__sec-shape">
        <div className="shape shape--1">
          <img src="assets/img/shape/s_shape1.png" alt="" />
        </div>
        <div className="shape shape--2">
          <img src="assets/img/shape/s_shape2.png" alt="" />
        </div>
      </div>
    </section>
  );
};

export default HowItWork;
