import React from "react";
import { useTranslation } from "react-i18next";
import { DAY_SALE_TOKEN, tokenSale } from "../../../constants/render";
import CountdownSection from "../../../components/countdown-section";

const Banner = () => {
  const { t } = useTranslation();

  return (
    <section className="hero hero__ico pos-rel" style={{ overflow: "hidden" }}>
      <div
        className="hero__bg"
        data-background="assets/img/bg/hero_bg.png"
      ></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="hero__content">
              <h1 className="title mb-25 wow fadeInUp" data-wow-delay="100ms">
                Gun Trader -
                <br />
                {t("banner.smart_trading")}
                <br />
                <p className="subTitle">
                  {t("banner.title_sale_1")}{" "}
                  <span>{t("banner.title_sale_2")}</span>{" "}
                  {t("banner.title_sale_3")}
                </p>
              </h1>
              <div className="btns wow fadeInUp" data-wow-delay="200ms">
                <a className="thm-btn" href="#token-sale">
                  {t("common.buy_token_now")}
                </a>
              </div>
              <div className="hero__progress mt-50 wow fadeInUp" data-wow-delay="300ms">
                <div className="progress-title ul_li_between">
                  <span>
                    <span>{t("common.raised")} -</span> 0 {tokenSale.token}
                  </span>
                  <span>
                    <span>{t("common.target")} -</span> {tokenSale.softCap}{" "}
                    {tokenSale.token}
                  </span>
                </div>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "5%" }}
                    aria-valuenow="5"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <ul className="ul_li_between">
                  <li>{t("common.pre_sell")}</li>
                  <li>{t("common.soft_cap")}</li>
                  <li>{t("common.bonus")}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="hero__explore-wrap text-center">
              <div className="hero__explore text-center">
                <div className="scroll-down"></div>
                <span>{t("common.about_us")}</span>
              </div>
              <div className="hero__countdown">
                <h6 className="text-center"> {t("banner.title_sale_4")}</h6>
                <div className="fugu-countdown-wrap">
                  <CountdownSection day={DAY_SALE_TOKEN} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero__shape">
        <div className="shape shape--1">
          <img src="assets/img/shape/h_shape.png" alt="" />
        </div>
        <div className="shape shape--2">
          <img src="assets/img/shape/h_shape2.png" alt="" />
        </div>
        <div className="shape shape--3">
          <img src="assets/img/shape/h_shape3.png" alt="" />
        </div>
      </div>
      <div className="hero__coin">
        <div className="coin coin--1">
          <img src="assets/img/icon/coin1.png" alt="" />
        </div>
        <div className="coin coin--2">
          <img src="assets/img/icon/coin2.png" alt="" />
        </div>
        <div className="coin coin--3">
          <img src="assets/img/icon/coin3.png" alt="" />
        </div>
        <div className="coin coin--4">
          <img src="assets/img/icon/coin4.png" alt="" />
        </div>
        <div className="coin coin--5">
          <img src="assets/img/icon/coin5.png" alt="" />
        </div>
        <div className="coin coin--6">
          <img src="assets/img/icon/coin6.png" alt="" />
        </div>
      </div>
    </section>
  );
};
export default Banner;
