exports.DAY_SALE_TOKEN = '2025-01-30 9:00:00';

exports.tokenSale = {
  token: 'GUN',
  tokenBuy: 'BNB',
  endTime: '2025-01-30 9:00:00',
  min: 0.1,
  max: 50,
  startingTime: 'Dec 30, 2024',
  endingTime: 'Jan 30, 2025',
  softCap: '90,000',
  hardCap: '55,000',
  exchangeRate: '1 BNB = 50,000 GUN',
  tokenSale: '40,000,000 (2%)',
  privateSale: '1 GUN = $ 0.5',
  raised: '$ 10000',
  nextRound: '1 GUN = $ 0.75',
  listing: '$ 1',
};
