import React from "react";

const Brand = () => {
  return (
    <section className="pt-145 pt-md-100" style={{ overflow: "hidden"}}>
      <div className="container">
        <div className="sec-title text-center mb-30">
          <h5 className="sec-title__subtitle">Our partners</h5>
          <h2 className="sec-title__title">Trusted by Leading Partners</h2>
        </div>

        <div className="row wow fadeInBottom" data-wow-delay="200ms">
          <div className="col-6 col-lg-3">
            <a href="https://labs.binance.com" className="partner" target="_blank" rel="noreferrer">
              <img src="assets/img/partners/1.png" alt="" />
              <p>labs.binance.com</p>

              <span className="screw screw--br"></span>
              <span className="screw screw--bl"></span>
              <span className="screw screw--tr"></span>
              <span className="screw screw--tl"></span>
            </a>
          </div>

          <div className="col-6 col-lg-3">
            <a href="https://bitkraft.vc" className="partner">
              <img src="assets/img/partners/2.png" alt="" />
              <p>bitkraft.vc</p>

              <span className="screw screw--br"></span>
              <span className="screw screw--bl"></span>
              <span className="screw screw--tr"></span>
              <span className="screw screw--tl"></span>
            </a>
          </div>

          <div className="col-6 col-lg-3">
            <a href="https://crypto.com" className="partner">
              <img src="assets/img/partners/3.png" alt="" />
              <p>crypto.com</p>

              <span className="screw screw--br"></span>
              <span className="screw screw--bl"></span>
              <span className="screw screw--tr"></span>
              <span className="screw screw--tl"></span>
            </a>
          </div>

          <div className="col-6 col-lg-3">
            <a href="https://huskycapitalllc.com" className="partner">
              <img src="assets/img/partners/4.png" alt="" />
              <p>huskycapitalllc.com</p>

              <span className="screw screw--br"></span>
              <span className="screw screw--bl"></span>
              <span className="screw screw--tr"></span>
              <span className="screw screw--tl"></span>
            </a>
          </div>

          <div className="col-6 col-lg-3">
            <a href="https://mechanism.capital" className="partner">
              <img src="assets/img/partners/5.png" alt="" />
              <p>mechanism.capital</p>

              <span className="screw screw--br"></span>
              <span className="screw screw--bl"></span>
              <span className="screw screw--tr"></span>
              <span className="screw screw--tl"></span>
            </a>
          </div>

          <div className="col-6 col-lg-3">
            <a href="https://magnuscapital.com" className="partner">
              <img src="assets/img/partners/6.png" alt="" />
              <p>magnuscapital.com</p>

              <span className="screw screw--br"></span>
              <span className="screw screw--bl"></span>
              <span className="screw screw--tr"></span>
              <span className="screw screw--tl"></span>
            </a>
          </div>

          <div className="col-6 col-lg-3">
            <a href="https://coinbase.com" className="partner">
              <img src="assets/img/partners/7.png" alt="" />
              <p>coinbase.com</p>

              <span className="screw screw--br"></span>
              <span className="screw screw--bl"></span>
              <span className="screw screw--tr"></span>
              <span className="screw screw--tl"></span>
            </a>
          </div>

          <div className="col-6 col-lg-3">
            <a href="https://multicoin.capital" className="partner">
              <img src="assets/img/partners/8.png" alt="" />
              <p>multicoin.capital</p>

              <span className="screw screw--br"></span>
              <span className="screw screw--bl"></span>
              <span className="screw screw--tr"></span>
            <span className="screw screw--tl"></span>
          </a>
        </div>
      </div>
      </div>
    </section>
  );
};
export default Brand;
