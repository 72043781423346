import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

import AppRoutesEnum from '../constants/route';
import HomePage from '../pages/home';
import BotTradingPage from '../pages/bot-trading';
import VaultPage from '../pages/vault';
import NotFoundPage from '../pages/404';

const AppRoutes = [
  {
    path: AppRoutesEnum.HOME,
    element: <HomePage />,
  },
  {
    path: AppRoutesEnum.BOT_TRADING,
    element: <BotTradingPage />,
  },
  {
    path: AppRoutesEnum.ARBITRAGE,
    element: <VaultPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

const AppRouter = () => {
  return (
    <Suspense fallback={<div className={'loading-container'}></div>}>
      <Routes>
        {AppRoutes.map((item) => {
          return <Route key={item.path} path={item.path} element={item.element} />;
        })}
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
