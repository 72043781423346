import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/layout';
import Breadcrumb from '../../components/breadcrumb';
import About from './about';
import HowItWork from './how-it-work';
import ArbitrageDeals from './arbitrage-deals';
import Pricing from './pricing';

const VaultPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Breadcrumb title={t('arbitrage')} />
      <Pricing />
      <HowItWork />
      {/* <ArbitrageDeals /> */}
      {/* <About /> */}
    </Layout>
  );
};
export default VaultPage;
