import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const CountdownBuyToken = ({ time }) => {
  const { t } = useTranslation();

  const calculateTimeLeft = () => {
    const difference = +new Date(time) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    timerComponents.push(
      <span key={interval + 'buy_token'} className="counter_box_buy">
        <span id={interval} className={`tk_counter ${interval}`}>
          {timeLeft[interval]}
        </span>
        <span className="tk_text">{t(`common.${interval}`)}</span>
      </span>,
    );
  });

  return (
    <div className="tk_countdown_time">
      {timerComponents.length ? timerComponents : <span>{t('common.time_up')}</span>}
    </div>
  );
};

export default CountdownBuyToken;
