import React, { useState, useEffect } from 'react';
import AppRoutesEnum from '../../../constants/route';
import { useTranslation } from 'react-i18next';
import ListItem from '@mui/material/ListItem';
import { useAccount, useDisconnect, useBalance } from 'wagmi';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import IconButton from '@mui/material/IconButton';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { TOKEN_ADDRESS } from '../../../constants/token-sale';
import { formatCurrency } from '../../../utils/number';
import { toast } from 'react-toastify';

const navBarItems = [
  {
    path: AppRoutesEnum.HOME,
    i18nKey: 'home',
  },
  {
    path: AppRoutesEnum.TOKEN,
    i18nKey: 'token',
  },
  {
    path: AppRoutesEnum.BOT_TRADING,
    i18nKey: 'bot_trading',
  },
  {
    path: AppRoutesEnum.ARBITRAGE,
    i18nKey: 'arbitrage',
  },
  {
    path: AppRoutesEnum.FAQ,
    i18nKey: 'faq.title',
  },
];

const Header = () => {
  const { t, i18n } = useTranslation();
  const { openConnectModal } = useConnectModal();
  const { address, status } = useAccount();
  const [isCheck, setIsCheck] = useState(false);
  const { disconnect } = useDisconnect();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { data: dataNXS } = useBalance({
    address: address,
    token: TOKEN_ADDRESS,
  });

  const onClickCopyRefLink = () => {
    const refLink = `https://guntrader.bot?ref=${address}`;
    if (typeof window !== 'undefined' && window.navigator && window.navigator.clipboard) {
      window.navigator.clipboard
        .writeText(refLink)
        .then(() => {
          toast.success(t('common.copy_successful'), {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch(() => {
          toast.success(t('common.copy_failed'), {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }
  };

  const handleCloseMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };


  useEffect(() => {
    function checkScreenSize() {
      const width = window.innerWidth;
      setIsCheck(width >= 1200);
    }
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return (
    <div>
      <header className="site-header header--transparent ico-header">
        <div className="header__main-wrap stricky">
          <div className="container">
            <div className="header__main ul_li_between">
              <div className="header__left ul_li">
                <div className="header__logo">
                  <a href={AppRoutesEnum.HOME}>
                    <img src="images/logo/logo_text.png" alt="" width={180}/>
                  </a>
                </div>
              </div>

              <div className="main-menu__wrap ul_li navbar navbar-expand-xl">
                <nav className="main-menu collapse navbar-collapse">
                  <ul>
                    {navBarItems.map((navBarItem) => (
                      <li key={navBarItem.path}>
                        <a className="scrollspy-btn" href={navBarItem.path}>
                          {t(`${navBarItem.i18nKey}`)}
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>

              <div className="header__action ul_li">
                {status === 'connected' ? (
                  <div className="header__language_icon">
                    <div className="dropdown">
                      <IconButton
                        className="dropdown-toggle"
                        id="dropdownMenuButton3"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ color: '#fff' }}
                      >
                        <AccountCircleIcon style={{ width: 32, height: 32 }} />
                      </IconButton>

                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton3">
                        <ListItem className="dropdown-item">
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              minWidth: '180px',
                            }}
                          >
                            <p>{address.slice(0, 6) + '...' + address.slice(-6)}</p>
                            <p style={{ fontWeight: '600' }}>
                              {formatCurrency(parseFloat(dataNXS?.formatted || 0).toFixed(2))} GUN
                            </p>
                          </div>
                        </ListItem>
                        <ListItem className="dropdown-item" onClick={onClickCopyRefLink}>
                          <div
                            style={{
                              width: '90%',
                            }}
                          >
                            {t('common.copy_link_ref')}
                          </div>
                        </ListItem>
                        <ListItem
                          className="dropdown-item"
                          onClick={() => {
                            disconnect();
                          }}
                        >
                          <div
                            style={{
                              width: '90%',
                            }}
                          >
                            {t('logout')}
                          </div>
                        </ListItem>
                      </div>
                    </div>
                  </div>
                ) : isCheck ? (
                  <div className="header__account" style={{ marginLeft: '10px' }}>
                    <a onClick={openConnectModal} href="#!">
                      {t('connect')}
                    </a>
                  </div>
                ) : (
                  <IconButton onClick={openConnectModal} style={{ color: '#fff' }}>
                    <AccountBalanceWalletIcon style={{ width: 32, height: 32 }} />
                  </IconButton>
                )}
                
                <div className="d-xl-none">
                  <a className="header__bar hamburger_menu" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                    <div className="header__bar-icon">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className={`body-overlay ${isMobileMenuOpen ? 'active' : null}`}></div>

      <aside className={`slide-bar ${isMobileMenuOpen ? 'show' : null}`}>
        <div className="close-mobile-menu" onClick={handleCloseMobileMenu}>
          <a className="tx-close"></a>
        </div>

        <nav className="side-mobile-menu">
          <a className="header__logo mb-30" href="#!">
            <img src="images/logo/logo_text.png" alt="" width={180} />
          </a>
          <ul id="mobile-menu-active">
            {navBarItems.map((navBarItem) => (
              <li key={navBarItem.path}>
                <a className="scrollspy-btn" href={navBarItem.path}>
                  {t(`${navBarItem.i18nKey}`)}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </aside>
    </div>
  );
};

export default Header;
