import React from "react";
import { useTranslation } from "react-i18next";

const Tokenomics = () => {
  const { t } = useTranslation();

  return (
    <section className="token pt-100 pt-md-20" style={{ overflow: "hidden" }}>
      <div className="container">
        <div className="row align-items-center mt-none-30">
          <div className="col-lg-5 mt-30">
            <div className="token__content wow fadeInLeft">
              <div className="sec-title mb-20">
                <h5 className="sec-title__subtitle">{t("tokenomics.title")}</h5>
                <h2 className="sec-title__title">{t("tokenomics.subtitle")}</h2>
              </div>
              <div className="token__info">
                <p>{t("tokenomics.description")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-7 mt-30">
            <div className="token__img wow fadeInRight" data-wow-delay="100ms">
              <img src="assets/img/token/token_chart.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Tokenomics;
