import React, { useState } from "react";
import { useTranslation } from "react-i18next";


const Pricing = () => {
  const [toggleBilled, setToggleBilled] = useState(false);
  const { t } = useTranslation();
  const [selectedCurrency1, setSelectedCurrency1] = useState('GUN');
  const [selectedCurrency2, setSelectedCurrency2] = useState('GUN');
  const [selectedCurrency3, setSelectedCurrency3] = useState('GUN');
  const [selectedCurrency4, setSelectedCurrency4] = useState('GUN');

  const toggleClass = () => {
    setToggleBilled(!toggleBilled);
  };

  return (
    <section className="about pos-rel pt-80 pt-md-70" style={{ overflow: "hidden" }}>    
      <div className="container">
      <div className="sec-title style2 mb-20">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <h2 className="sec-title__title text-50 mb-lg-0 mb-25 text-center text-lg-start">
              Smart Pool Bot Trading
            </h2>
          </div>
          <div className="col-lg-6">
            <p className="text-center text-lg-start">
              Our Pool Bot Trading solution uses AI-powered bots to automate crypto trading across exchanges, maximizing profits and delivering consistent returns to users.
            </p>
          </div>
        </div>
      </div>

          <div className="row">
            <div
              className="col-12 col-md-6 col-xl-3 fadeInUp wow animated"
              data-wow-duration="1.2s"
              data-wow-delay="0.1s"
            >
              <div className="apool">
                <h3 className="apool__title">1 Month</h3>

                <ul className="nav nav-tabs apool__tabs apool__tabs--yellow" id="apool__tabs1" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className={`${selectedCurrency1 === 'GUN' ? 'active' : ''}`}
                      data-bs-toggle="tab"
                      data-bs-target="#atab-1"
                      type="button"
                      role="tab"
                      aria-controls="atab-1"
                      aria-selected={selectedCurrency1 === 'GUN'}
                      onClick={() => setSelectedCurrency1('GUN')}
                      style={{color: selectedCurrency1 === 'GUN' ? '#56efad' : '#fff'}}
                    >
                      GUN
                    </a>
                  </li>

                  <li className="nav-item" role="presentation">
                    <a
                      className={`${selectedCurrency1 === 'USDT' ? 'active' : ''}`}
                      data-bs-toggle="tab"
                      data-bs-target="#atab-2"
                      type="button"
                      role="tab"
                      aria-controls="atab-2"
                      aria-selected={selectedCurrency1 === 'USDT'}
                      onClick={() => setSelectedCurrency1('USDT')}
                      style={{color: selectedCurrency1 === 'USDT' ? '#56efad' : '#fff'}}
                    >
                      USDT
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="atab-1" role="tabpanel">
                    <div className="apool__content">
                      <span className="apool__value">10 - 200 GUN</span>
                      <span className="apool__profit">
                        Monthly profit (%): <b>3.0</b>
                      </span>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="atab-2" role="tabpanel">
                    <div className="apool__content">
                      <span className="apool__value">5 - 200 USDT</span>
                      <span className="apool__profit">
                        Monthly profit (%): <b>2.0</b>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="apool__group">
                  <input id="pool1" name="pool1" className="apool__input" placeholder="Enter amount" type="number"
                    step="0.0001"
                    min="0.1"/>
                </div>

                <button className="apool__btn">Invest</button>

                <span className="block-icon block-icon--yellow">
                  <i className="ti ti-database-dollar"></i>
                </span>
                <span className="screw screw--lines-tr"></span>
              </div>
            </div>

            <div
              className="col-12 col-md-6 col-xl-3 fadeInUp wow animated"
              data-wow-duration="1.2s"
              data-wow-delay="0.1s"
            >
              <div className="apool">
                <h3 className="apool__title">3 Month</h3>

                <ul className="nav nav-tabs apool__tabs apool__tabs--orange" id="apool__tabs2" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className={`${selectedCurrency2 === 'GUN' ? 'active' : ''}`}
                      data-bs-toggle="tab"
                      data-bs-target="#atab-3"
                      type="button"
                      role="tab"
                      aria-controls="atab-3"
                      aria-selected={selectedCurrency2 === 'GUN'}
                      onClick={() => setSelectedCurrency2('GUN')}
                      style={{color: selectedCurrency2 === 'GUN' ? '#56efad' : '#fff'}}
                    >
                      GUN
                    </a>
                  </li>

                  <li className="nav-item" role="presentation">
                    <a
                      className={`${selectedCurrency2 === 'USDT' ? 'active' : ''}`}
                      data-bs-toggle="tab"
                      data-bs-target="#atab-4"
                      type="button"
                      role="tab"
                      aria-controls="atab-4"
                      aria-selected={selectedCurrency2 === 'USDT'}
                      onClick={() => setSelectedCurrency2('USDT')}
                      style={{color: selectedCurrency2 === 'USDT' ? '#56efad' : '#fff'}}
                    >
                      USDT
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="atab-3" role="tabpanel">
                    <div className="apool__content">
                      <span className="apool__value">Min 50 GUN</span>
                      <span className="apool__profit">
                        Monthly profit (%): <b>5.0</b>
                      </span>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="atab-4" role="tabpanel">
                    <div className="apool__content">
                      <span className="apool__value">Min 50 USDT</span>
                      <span className="apool__profit">
                        Monthly profit (%): <b>3.5</b>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="apool__group">
                  <input id="pool2" name="pool2" className="apool__input" placeholder="Enter amount" type="number"
                    step="0.0001"
                    min="0.1"/>
                </div>

                <button className="apool__btn">Invest</button>

                <span className="block-icon block-icon--orange">
                  <i className="ti ti-database-dollar"></i>
                </span>
                <span className="screw screw--lines-tr"></span>
              </div>
            </div>

            <div
              className="col-12 col-md-6 col-xl-3 fadeInUp wow animated"
              data-wow-duration="1.2s"
              data-wow-delay="0.1s"
            >
              <div className="apool">
                <h3 className="apool__title">6 Month</h3>

                <ul className="nav nav-tabs apool__tabs apool__tabs--blue" id="apool__tabs3" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className={`${selectedCurrency3 === 'GUN' ? 'active' : ''}`}
                      data-bs-toggle="tab"
                      data-bs-target="#atab-5"
                      type="button"
                      role="tab"
                      aria-controls="atab-5"
                      aria-selected={selectedCurrency3 === 'GUN'}
                      onClick={() => setSelectedCurrency3('GUN')}
                      style={{color: selectedCurrency3 === 'GUN' ? '#56efad' : '#fff'}}
                    >
                      GUN
                    </a>
                  </li>

                  <li className="nav-item" role="presentation">
                    <a
                      className={`${selectedCurrency3 === 'USDT' ? 'active' : ''}`}
                      data-bs-toggle="tab"
                      data-bs-target="#atab-6"
                      type="button"
                      role="tab"
                      aria-controls="atab-6"
                      aria-selected={selectedCurrency3 === 'USDT'}
                      onClick={() => setSelectedCurrency3('USDT')}
                      style={{color: selectedCurrency3 === 'USDT' ? '#56efad' : '#fff'}}
                    >
                      USDT
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div className="tab-pane fade show active" id="atab-5" role="tabpanel">
                    <div className="apool__content">
                      <span className="apool__value">Min 200 GUN</span>
                      <span className="apool__profit">
                        Monthly profit (%): <b>7.0</b>
                      </span>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="atab-6" role="tabpanel">
                    <div className="apool__content">
                      <span className="apool__value">Min 100 USDT</span>
                      <span className="apool__profit">
                        Monthly profit (%): <b>4.2</b>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="apool__group">
                  <input id="pool3" name="pool3" className="apool__input" placeholder="Enter amount" type="number"
                    step="0.0001"
                    min="0.1"/>
                </div>

                <button className="apool__btn">Invest</button>

                <span className="block-icon block-icon--blue">
                  <i className="ti ti-database-dollar"></i>
                </span>
                <span className="screw screw--lines-tr"></span>
              </div>
            </div>

            <div
              className="col-12 col-md-6 col-xl-3 fadeInUp wow animated"
              data-wow-duration="1.2s"
              data-wow-delay="0.1s"
            >
              <div className="apool">
                <h3 className="apool__title">12 Month</h3>

                <ul className="nav nav-tabs apool__tabs apool__tabs--green" id="apool__tabs3" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className={`${selectedCurrency4 === 'GUN' ? 'active' : ''}`}
                      data-bs-toggle="tab"
                      data-bs-target="#atab-7"
                      type="button"
                      role="tab"
                      aria-controls="atab-7"
                      aria-selected={selectedCurrency4 === 'GUN'}
                      onClick={() => setSelectedCurrency4('GUN')}
                      style={{color: selectedCurrency4 === 'GUN' ? '#56efad' : '#fff'}}
                    >
                      GUN
                    </a>
                  </li>

                  <li className="nav-item" role="presentation">
                    <a
                      className={`${selectedCurrency4 === 'USDT' ? 'active' : ''}`}
                      data-bs-toggle="tab"
                      data-bs-target="#atab-8"
                      type="button"
                      role="tab"
                      aria-controls="atab-8"
                      aria-selected={selectedCurrency4 === 'USDT'}
                      onClick={() => setSelectedCurrency4('USDT')}
                      style={{color: selectedCurrency4 === 'USDT' ? '#56efad' : '#fff'}}
                    >
                      USDT
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div className="tab-pane fade show active" id="atab-7" role="tabpanel">
                    <div className="apool__content">
                      <span className="apool__value">Min 500 GUN</span>
                      <span className="apool__profit">
                        Monthly profit (%): <b>9.0</b>
                      </span>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="atab-8" role="tabpanel">
                    <div className="apool__content">
                      <span className="apool__value">Min 200 USDT</span>
                      <span className="apool__profit">
                        Monthly profit (%): <b>5.5</b>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="apool__group">
                  <input id="pool4" name="pool4" className="apool__input" placeholder="Enter amount" type="number"
                    step="0.0001"
                    min="0.1"/>
                </div>

                <button className="apool__btn">Invest</button>

                <span className="block-icon block-icon--green">
                  <i className="ti ti-database-dollar"></i>
                </span>
                <span className="screw screw--lines-tr"></span>
              </div>
            </div>
          </div>
        </div>

      <div className="faq__sec-shape">
        <div className="shape shape--1">
          <img src="assets/img/shape/s_shape1.png" alt="" />
        </div>
        <div className="shape shape--2">
          <img src="assets/img/shape/s_shape2.png" alt="" />
        </div>
      </div>
    </section>
  );
};

export default Pricing;
