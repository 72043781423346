import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './router';
import { RainbowKitProvider, connectorsForWallets } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { bsc, bscTestnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { metaMaskWallet, trustWallet, coin98Wallet } from '@rainbow-me/rainbowkit/wallets';

const { chains, publicClient, webSocketPublicClient } = configureChains([bsc, bscTestnet], [publicProvider()]);

const projectId = 'cf0b2d75ef7f0db52406eee162842b2e';
const connectors = connectorsForWallets([
  {
    groupName: 'Gun Trader',
    wallets: [
      metaMaskWallet({ chains, projectId }),
      trustWallet({ chains, projectId }),
      coin98Wallet({ chains, projectId }),
      // injectedWallet({ chains }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const App = () => {  
  return(
  <BrowserRouter>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <AppRouter />
      </RainbowKitProvider>
    </WagmiConfig>
  </BrowserRouter>
)};

export default App;
