import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/layout';
import Breadcrumb from '../../components/breadcrumb';
import Pricing from './pricing';
import Service from './service';
import Content from './content';

const BotTradingPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Breadcrumb title={t('bot_trading')} />
      <Pricing />
      {/* <About /> */}
      <Service />
      {/* <Content /> */}
    </Layout>
  );
};
export default BotTradingPage;
