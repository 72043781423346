import React from "react";
import { useTranslation } from "react-i18next";

const Service = () => {
  const { t } = useTranslation();

  return (
    <section className="about pos-rel pt-80 pt-md-70" style={{ overflow: "hidden" }}>
      <div className="container">
        <div
          className="sec-title style2 text-center mb-20 wow fadeInUp"
          data-wow-delay="100ms"
        >
          <h2 className="sec-title__title text-50 mb-25">
            {t("features_bot_trading.title")}
          </h2>
          <p>{t("features_bot_trading.description")}</p>
        </div>

        <div className="footer__newsletter row align-items-center wow fadeInUp" data-wow-delay="200ms">
          <div className="col-lg-4">
            <div className="user-option__item">
              <ul className="user-option__list list-unstyled mt-45">
                <li>
                  <span>
                    <img src="assets/img/icon/check_badge.svg" alt="" />
                  </span>
                  <h4>Automated Futures Trading</h4>
                  <p>Execute automated futures trades without manual intervention, ensuring round-the-clock market engagement.</p>
                </li>
                <li>
                  <span>
                    <img src="assets/img/icon/check_badge.svg" alt="" />
                  </span>
                  <h4>Real-Time Price Updates</h4>
                  <p>Stay ahead of the market with instant, accurate price notifications sent directly to your Telegram.</p>
                </li>
                <li>
                  <span>
                    <img src="assets/img/icon/check_badge.svg" alt="" />
                  </span>
                  <h4>Maximized Profit Potential</h4>
                  <p>Leverage advanced AI algorithms designed to capitalize on market trends and maximize returns.</p>
                </li>
                <li>
                  <span>
                    <img src="assets/img/icon/check_badge.svg" alt="" />
                  </span>
                  <h4>Risk-Managed Trading</h4>
                  <p>Built-in Take-Profit and Stop-Loss features to minimize exposure and protect your investments during volatile market movements.</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="user-option__img text-center">
              <img src="assets/img/bg/uo_bg.png" alt="" />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="user-option__item style2">
              <ul className="user-option__list mt-45 list-unstyled">
                <li>
                  <span>
                    <img src="assets/img/icon/check_badge.svg" alt="" />
                  </span>
                  <h4>Seamless Trade Execution</h4>
                  <p>Automatic order placement based on pre-set strategies, ensuring fast and accurate trade execution.</p>
                </li>
                <li>
                  <span>
                    <img src="assets/img/icon/check_badge.svg" alt="" />
                  </span>
                  <h4>Complete Transparency</h4>
                  <p>Full transparency with real-time tracking of every trade and market activity directly via Telegram.</p>
                </li>
                <li>
                  <span>
                    <img src="assets/img/icon/check_badge.svg" alt="" />
                  </span>
                  <h4>High-Level Security</h4>
                  <p>State-of-the-art encryption and secure protocols to safeguard your funds and trading data.</p>
                </li>
                <li>
                  <span>
                    <img src="assets/img/icon/check_badge.svg" alt="" />
                  </span>
                  <h4>Consistent Earnings</h4>
                  <p>Generate consistent profits with optimized futures strategies that are continuously adjusted to market conditions.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;
