import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CountdownBuyToken from '../../../components/countdown-buy-token';
import { useAccount, useBalance, useContractWrite, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { TOKEN_ADDRESS, CONTRACT_SALE_TOKEN, ABI } from '../../../constants/token-sale';
import { tokenSale } from '../../../constants/render';
import { useLocation } from 'react-router-dom';

const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';

const TokenSale = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { openConnectModal } = useConnectModal();
  const { address, isConnected } = useAccount();
  const [isBuy, setIsBuy] = useState(false);
  const [amountBNB, setAmountBNB] = useState(null);

  const refValue = useMemo(() => {
    return new URLSearchParams(location.search).get('ref') || ADDRESS_ZERO;
  }, [location.search, address]);

  const { data: dataBalance, refetch: refetchBalance } = useBalance({
    address: address,
  });

  const { refetch: refetchBalanceNXS } = useBalance({
    address: address,
    token: TOKEN_ADDRESS,
  });

  const { config: buyTokensConfig, isError: isErrorBuyTokensConfig } = usePrepareContractWrite({
    address: CONTRACT_SALE_TOKEN,
    abi: ABI,
    functionName: 'buyTokens',
    args: [refValue],
    value: BigInt(Math.round((amountBNB || 0) * 10 ** 18)),
  });

  const { write: buyTokens, data: dataContractBuy } = useContractWrite({
    ...buyTokensConfig,
    from: address,
    onError(error) {
      toast.error(error?.shortMessage || t('alert.transaction_failed'), {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    },
  });

  const { isLoading: isLoadingTransaction } = useWaitForTransaction({
    hash: dataContractBuy?.hash,
    onSuccess: () => {
      refetchBalance();
      refetchBalanceNXS();
      toast.success(t('alert.transaction_successful'), {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    },
    onError: (error) => {
      toast.error(error?.shortMessage || t('alert.transaction_failed'), {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    },
  });

  const onClickBuyTokenNow = useCallback(() => {
    try {
      if (!isConnected) openConnectModal();
      setIsBuy(true);
    } catch (e) {
      toast.error(t('alert.wallet_connection_failed'), {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [t, openConnectModal, isConnected]);

  const addTokenToMetamask = useCallback(async () => {
    try {
      if (window.ethereum && window.ethereum.isMetaMask) {
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: TOKEN_ADDRESS,
              symbol: 'GUN',
              decimals: 18,
              image: "https://pbs.twimg.com/profile_images/1866161997512892416/-kT7dsSE_400x400.jpg",
            },
          },
        });

        if (wasAdded) {
          toast.success(t('alert.token_added'), {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error(t('alert.token_not_added'), {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      } else {
        toast.info(t('alert.install_metamask'), {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, []);

  useEffect(() => {
    if (isLoadingTransaction) {
      toast.info(t('alert.transaction_pending'), {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [t, isLoadingTransaction]);

  const styles = {
    marginTop20: {
      marginTop: '20px',
    },
    errorText: {
      marginLeft: '20px',
      color: 'red',
      fontSize: 14,
      textAlign: 'start',
    },
    flexSpaceBetween: {
      marginTop: '6px',
      display: 'flex',
      justifyContent: 'space-between',
      color: '#B3B3C0',
    },
    marginLeft20: {
      marginLeft: '20px',
    },
    marginRight20: {
      marginRight: '20px',
    },
  };

  const onClickBuyTokens = () => {
    if (
      amountBNB &&
      amountBNB >= tokenSale.min &&
      amountBNB <= tokenSale.max &&
      !isErrorBuyTokensConfig &&
      !isLoadingTransaction
    ) {
      buyTokens();
    }
  };

  const renderErrorMessage = () => {
    if (amountBNB > tokenSale.max || amountBNB < tokenSale.min) {
      return t('errors.amount_bnb', { min: tokenSale.min, max: tokenSale.max });
    } else if (amountBNB >= parseFloat(dataBalance.formatted)) {
      return t('errors.insufficient_balance');
    }
    return null;
  };

  return (
    <section id="token-sale" className="token-info pos-rel pt-120 pt-md-100 pb-130 pb-md-30" style={{ overflow: "hidden" }}>
      <div className="container">
            <div className="sec-title text-center mb-50 wow fadeInUp" data-wow-delay="100ms">
              <h5 className="sec-title__subtitle">{t('token_sale.ico_gun_token')}</h5>
              <h2 className="sec-title__title">
              {t('token_sale.ico_tokens')} {t('token_sale.details_and_sale')}
              </h2>
        </div>

        <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
          <div className="col-lg-6 order-lg-2 wow fadeInLeft">
            <div className="tk_counter_inner text-center" style={{ maxWidth: '400px', margin: 'auto' }}>
              <h3 style={{ marginBottom: '4px', color: '#fff', textTransform: 'capitalize', fontSize: '26px' }}>
                {`${t('common.private_sale')} - ${t('common.lock')} 50%`}
              </h3>
              <h6 style={{ marginBottom: '32px', color: '#B3B3C0', fontWeight: '600' }}>
                {`( ${t('common.vesting')} 5 ${t('common.months')} - 10% ${t('common.per_month')} )`}
              </h6>
              <CountdownBuyToken time={tokenSale.endTime} />
              {isBuy && isConnected ? (
                <div className="token_sale" style={{ marginTop: '20px' }}>
                  <input
                    type="number"
                    step="0.00000001"
                    min="0.1"
                    required
                    placeholder={`${t('common.amount')} ${tokenSale.tokenBuy}`}
                    onChange={(event) => setAmountBNB(event.target.value)}
                  />
                  <Button
                    className="btn-default"
                    id="fugu--submit-btn"
                    size="large"
                    endIcon={isLoadingTransaction && <CircularProgress size={20} color="inherit" />}
                    onClick={onClickBuyTokens}
                  >
                    {t('common.buy')}
                  </Button>
                  {amountBNB && <p style={styles.errorText}>{renderErrorMessage()}</p>}

                  <div style={styles.flexSpaceBetween}>
                    <text style={styles.marginLeft20}>{address.slice(0, 6) + '...' + address.slice(-6)}</text>
                    <text style={styles.marginRight20}>
                      {`${parseFloat(dataBalance?.formatted || 0).toFixed(3)} ${dataBalance?.symbol || 'BNB'}`}
                    </text>
                  </div>
                </div>
              ) : (
                <button className="thm-btn" onClick={onClickBuyTokenNow} style={{ marginTop: '20px' }}>
                  {t('common.buy_token_now')} <i className="ion-ios-arrow-thin-right"></i>
                </button>
              )}

              <a
                className="btn btn-white btn-radius"
                style={{
                  width: '240px',
                  color: '#eea849',
                  marginTop: '10px',
                  textDecoration: 'underline',
                }}
                onClick={addTokenToMetamask}
              >
                {t('common.add_token_wallet')}
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-6 wow fadeInRight order-md-1" data-wow-delay="100ms">
            <div className="token-info--info-wrap">
              <ul className="token-info__list token-info--start">
                <li>
                  <h4>{t('common.start')}</h4>
                  <span>{tokenSale.startingTime}</span>
                </li>
                <li>
                  <h4>{t('common.end')}</h4>
                  <span>{tokenSale.endingTime}</span>
                </li>
                <li>
                  <h4>{t('common.token_sale')}</h4>
                  <span>{tokenSale.tokenSale}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-6 wow fadeInRight order-md-3" data-wow-delay="100ms">
            <div className="token-info--info-wrap">
              <ul className="token-info__list token-info--end">
                <li>
                  <h4>{t('common.acceptable_currency')}</h4>
                  <span>BNB</span>
                </li>
                <li>
                  <h4>{t('common.exchange_rate')}</h4>
                  <span>1 BNB = 50,000 GUN</span>
                </li>
                <li>
                  <h4>{t('common.list_dex')}</h4>
                  <span>$ 0.02</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="faq__sec-shape">
        <div className="shape shape--1">
          <img src="assets/img/shape/ti_shape.png" alt="" />
        </div>
        <div className="shape shape--2">
          <img src="assets/img/shape/s_shape2.png" alt="" />
        </div>
      </div>
    </section>
  );
};

export default TokenSale;
