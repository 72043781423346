import React, { useEffect } from 'react';
import Banner from './banner';
import AboutToken from './about-token';
import ChooseToken from './choose-token';
import TokenSale from './token-sale';
import Tokenomics from './tokenomics';
import Roadmap from './roadmap';
import Faq from './faq';
import Brand from './brand';
import Layout from '../../components/layout';

const HomePage = () => {
  useEffect(() => {
    const hash = window.location.hash;
    
    if (hash) {
      // Thêm setTimeout để đảm bảo DOM đã được render
      setTimeout(() => {
        const element = document.getElementById(hash.slice(1));
        if (element) {
          const headerOffset = 100; // Điều chỉnh offset nếu có fixed header
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        }
      }, 100);
    }
  }, []);

  return (
    <Layout>
      <Banner />
      <AboutToken />
      <ChooseToken />
      <TokenSale />
      <Tokenomics />
      <Roadmap />
      <Brand />
      <Faq />
    </Layout>
  );
};
export default HomePage;
