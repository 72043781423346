import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const CountdownSection = ({ day }) => {
  const { t } = useTranslation();

  const calculateTimeLeft = () => {
    // Parse input date string to moment object in UTC
    const targetDate = moment.utc(day, 'YYYY-MM-DD HH:mm:ss');
    // Get current time in UTC
    const now = moment.utc();
    
    // Calculate difference
    const difference = targetDate.diff(now);

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="fugu-countdown-item">
        <div className="countdown-days">
          <div className="number">{timeLeft?.days || 0}</div>
        </div>
        <p>{t(`common.days`)}</p>
      </div>
      <div className="fugu-countdown-item">
        <div className="countdown-hours">
          <div className="number">{timeLeft?.hours || 0}</div>
        </div>
        <p>{t(`common.hours`)}</p>
      </div>
      <div className="fugu-countdown-item">
        <div className="countdown-minutes">
          <div className="number">{timeLeft?.minutes || 0}</div>
        </div>
        <p>{t(`common.minutes`)}</p>
      </div>
      <div className="fugu-countdown-item">
        <div className="countdown-seconds">
          <div className="number">{timeLeft?.seconds || 0}</div>
        </div>
        <p>{t(`common.seconds`)}</p>
      </div>
    </>
  );
};

export default CountdownSection;
