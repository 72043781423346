import axios from 'axios';

let hasAnyNetworkDialogShown = false;
const HONEYPOT = 'https://api.honeypot.is/';

const request = axios.create({
  baseURL: HONEYPOT,
  timeout: 8000,
  headers: { Accept: '*/*' },
});

const rejectError = (err, validNetwork) => {
  if (validNetwork !== false) {
    return Promise.reject(err);
  }
  return Promise.reject('Error network');
};

request.interceptors.response.use(
  (response) => response,
  async (error) => {
    const validNetwork = navigator.onLine;
    if (validNetwork === false && !hasAnyNetworkDialogShown) {
      hasAnyNetworkDialogShown = true;
    }

    return rejectError(error.response.data.error || error.message, validNetwork);
  },
);

export const getInfoContract = (address) => {
  return request.get('v2/IsHoneypot', { params: { address } });
};
