import React from "react";
import { useTranslation } from "react-i18next";

const AboutToken = () => {
  const { t } = useTranslation();

  return (
    <section
      id="about"
      className="about pos-rel pb-120 pb-md-100"
      style={{ overflow: "hidden" }}
    >
      <div className="container">
        <div className="row align-items-center mt-none-30">
          <div className="col-lg-6 mt-30">
            <div className="about__img pos-rel wow fadeInLeft">
              <img src="assets/img/about/about_img.png" alt="" />
              <div className="about__shape">
                <img src="assets/img/shape/about_shape.png" alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-30">
            <div
              className="about__content wow fadeInRight"
              data-wow-delay="100ms"
            >
              <div className="sec-title mb-35">
                <h5 className="sec-title__subtitle">{t("about_us.title")}</h5>
                <h2 className="sec-title__title mb-25">
                  {t("about_us.subtitle")}
                </h2>
                <p>{t("about_us.description")}</p>
              </div>
              <ul className="about__list ul_li">
                <li>
                  <img src="assets/img/icon/a_arrow.svg" alt="" />
                  AI Trading Bot
                </li>
                <li>
                  <img src="assets/img/icon/a_arrow.svg" alt="" />
                  Arbitrage
                </li>
                <li>
                  <img src="assets/img/icon/a_arrow.svg" alt="" />
                  Transparent
                </li>
                <li>
                  <img src="assets/img/icon/a_arrow.svg" alt="" />
                  Community
                </li>
                <li>
                  <img src="assets/img/icon/a_arrow.svg" alt="" />
                  Earnings
                </li>
                <li>
                  <img src="assets/img/icon/a_arrow.svg" alt="" />
                  Security
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="about__sec-shape">
        <img src="assets/img/shape/s_shape2.png" alt="" />
      </div>
    </section>
  );
};
export default AboutToken;
