import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";

const pools = [
  {
    apr: 36,
    apr_month: 3,
    maximum: 1000,
    daily: 0.1,
  },
  {
    apr: 60,
    apr_month: 5,
    maximum: 5000,
    daily: 0.17,
  },
];

const Pricing = () => {
  const [toggleBilled, setToggleBilled] = useState(false);
  const { t } = useTranslation();

  const toggleClass = () => {
    setToggleBilled(toggleBilled);
  };

  return (
    <section className="about pos-rel pt-80 pt-md-70 pb-105 pb-md-85" style={{ overflow: "hidden" }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-6 wow fadeInLeft" data-wow-delay="200ms">
            <div
              className="tournament__box-wrap active"
              style={{ margin: "auto" }}
            >
              <svg
                className="price-bg"
                viewBox="0 0 166 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.00792892 55V11C0.00792892 11 -0.729072 0.988 12.0079 1C24.7449 1.012 160.008 0 160.008 0C160.008 0 172.491 1.863 161.008 10C148.995 18.512 115.008 48 115.008 48C115.008 48 110.021 55.238 90.0079 55C69.9949 54.762 0.00792892 55 0.00792892 55Z"
                  fill="currentcolor"
                />
              </svg>

              <div className="tournament__box-price">
                <span>{t("common.apr")}</span>
                <span>{toggleBilled ? pools[0].apr : pools[1].apr}%</span>
              </div>
              <div className="tournament__box-caption">
                <ul className="nav nav-tabs apool__tabs apool__tabs--yellow" id="apool__tabs1" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a
                      className={`${toggleBilled === true ? 'active' : ''}`}
                      data-bs-toggle="tab"
                      data-bs-target="#atab-1"
                      type="button"
                      role="tab"
                      aria-controls="atab-1"
                      aria-selected={toggleBilled === true}
                      onClick={() => setToggleBilled(true)}
                      style={{ color: toggleBilled === true ? '#a565f3' : '#fff' }}
                    >
                      <span className="sub">
                        {1} {t("common.months")}
                      </span>
                    </a>
                  </li>

                  <li className="nav-item" role="presentation">
                    <a
                      className={`${toggleBilled === false ? 'active' : ''}`}
                      data-bs-toggle="tab"
                      data-bs-target="#atab-2"
                      type="button"
                      role="tab"
                      aria-controls="atab-2"
                      aria-selected={toggleBilled === false}
                      onClick={() => setToggleBilled(false)}
                      style={{ color: toggleBilled === false ? '#a565f3' : '#fff' }}
                    >
                      <span className="sub">
                        {3} {t("common.months")}
                      </span>
                    </a>
                  </li>
                </ul>
                <h4 className="title">
                  {toggleBilled ? pools[0].apr_month : pools[1].apr_month}%{" "}
                  {t("common.per_month")}
                </h4>
              </div>

              <div
                style={{
                  margin: "auto",
                  marginTop: "32px",
                  textAlign: "center",
                }}
              >
                <ul className="tournament__box-list list-wrap">
                  <li>
                    <div className="tournament__box-list-item">
                      <h6 className="tournament__player-name">
                        {t("vault_pricing.maximum_investment")}
                      </h6>
                      <span className="tournament__player-price">
                        {toggleBilled ? pools[0].maximum : pools[1].maximum}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="tournament__box-list-item">
                      <h6 className="tournament__player-name">
                        {t("vault_pricing.daily_interest")}
                      </h6>
                      <span className="tournament__player-price">
                        {toggleBilled ? pools[0].daily : pools[1].daily}
                      </span>
                    </div>
                  </li>
                </ul>
                <button className="thm-btn mt-20" type="submit">
                  Coming Soon
                </button>
              </div>
            </div>
          </div>
          <Box className="col-lg-7 col-md-6 wow fadeInRight" data-wow-delay="200ms" sx={{
            mt: { md: 0, xs: 5 }
          }}>
            <div className="blc-about__content">
              <div className="sec-title style2 mb-40">
                <h2 className="sec-title__title text-50 mb-25">
                  Unleashing the Power of Pool Arbitrage Trading
                </h2>
                <p>
                  In the dynamic world of crypto trading, our Arbitrage Pool offers unparalleled efficiency and profitability. Designed to take advantage of price discrepancies across multiple exchanges, this pool allows users to maximize returns with minimized risk, all while benefiting from automated trading strategies.
                </p>
              </div>
              <ul className="blc-about__list ul_li mt-none-20">
                <li>
                  <img src="assets/img/icon/ul_icon.svg" alt="" />
                  Rewards: 36-60% APR
                </li>
                <li>
                  <img src="assets/img/icon/ul_icon.svg" alt="" />
                  Pool Size: 1,000 - 5,000 GUN
                </li>
                <li>
                  <img src="assets/img/icon/ul_icon.svg" alt="" />
                  Deposits: From 100 GUN
                </li>
                <li>
                  <img src="assets/img/icon/ul_icon.svg" alt="" />
                  Daily Payouts: 0.1%-0.17%
                </li>
              </ul>
            </div>
          </Box>
        </div>
      </div>

      {/* <div className="container">
        <div
          className="sec-title style2 text-center mb-20 wow fadeInUp"
          data-wow-delay="100ms"
        >
          <h2 className="sec-title__title text-50 mb-25">
            {t("vault_pricing.title")}
          </h2>
        </div>

        <div
          className="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="col-4 wow fadeInLeft" data-wow-delay="200ms">
            <div
              className="fugu--team-wrap"
              style={{
                height: "80%",
              }}
            >
              <div className="fugu--team-data">
                <h4>{t("vault_pricing.arbitrage_pools")}</h4>
                <p>$374 103</p>
              </div>
            </div>
          </div>
          <div className="col-4 wow fadeInUp" data-wow-delay="200ms">
            <div
              className="fugu--team-wrap"
              style={{
                height: "80%",
              }}
            >
              <div className="fugu--team-data">
                <h4>{t("vault_pricing.members")}</h4>
                <p>595</p>
              </div>
            </div>
          </div>
          <div className="col-4 wow fadeInRight" data-wow-delay="200ms">
            <div
              className="fugu--team-wrap"
              style={{
                height: "80%",
              }}
            >
              <div className="fugu--team-data">
                <h4>{t("vault_pricing.monthly_profit")}</h4>
                <p>4-6.5%</p>
              </div>
            </div>
          </div>
        </div>

        <div
          className="pricing-btn d-flex align-items-center justify-content-center flex-wrap wow fadeInUp"
          data-wow-delay="300ms"
          style={{
            textTransform: "capitalize",
            marginBottom: "32px",
            marginTop: "24px",
          }}
        >
          <label className="mb-3 mb-lg-0">3 {t("common.months")}</label>
          <div
            className=" form-check form-switch mb-2 mb-lg-0"
            onClick={toggleClass}
          >
            <input
              className="form-check-input btn-toggle price-deck-trigger mb-2 mb-lg-0"
              type="checkbox"
            />
          </div>
          <label className="mb-3 mb-lg-0">6 {t("common.months")}</label>
        </div>

        <div className="row">
          <div
            className="col-lg-4 col-md-6 wow fadeInLeft"
            style={{marginBottom: "24px"}}
            data-wow-delay="400ms"
          >
            <div
              className="tournament__box-wrap active"
              style={{ margin: "auto" }}
            >
              <svg
                className="price-bg"
                viewBox="0 0 166 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.00792892 55V11C0.00792892 11 -0.729072 0.988 12.0079 1C24.7449 1.012 160.008 0 160.008 0C160.008 0 172.491 1.863 161.008 10C148.995 18.512 115.008 48 115.008 48C115.008 48 110.021 55.238 90.0079 55C69.9949 54.762 0.00792892 55 0.00792892 55Z"
                  fill="currentcolor"
                />
              </svg>

              <div className="tournament__box-price">
                <span>{t("common.apr")}</span>
                <span>{toggleBilled ? pools[0].apr : pools[1].apr}%</span>
              </div>

              <div
                className="tournament__box-caption"
                style={{ marginTop: "50px" }}
              >
                <span className="sub">
                  {t("vault_pricing.basic")} - {toggleBilled ? 3 : 6} {t("common.months")}
                </span>
                <h4 className="title">
                  {toggleBilled ? pools[0].apr_month : pools[1].apr_month}%{" "}
                  {t("common.per_month")}
                </h4>
              </div>

              <div
                style={{
                  margin: "auto",
                  marginTop: "32px",
                  textAlign: "center",
                }}
              >
                <ul className="tournament__box-list list-wrap">
                  <li>
                    <div className="tournament__box-list-item">
                      <h6 className="tournament__player-name">
                        {t("vault_pricing.maximum_investment")}
                      </h6>
                      <span className="tournament__player-price">
                        {toggleBilled ? pools[0].maximum : pools[1].maximum}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="tournament__box-list-item">
                      <h6 className="tournament__player-name">
                        {t("vault_pricing.daily_interest")}
                      </h6>
                      <span className="tournament__player-price">
                        {toggleBilled ? pools[0].daily : pools[1].daily}
                      </span>
                    </div>
                  </li>
                </ul>
                <Button
                  className="thm-btn thm-btn--dark"
                  style={{
                    marginTop: "16px",
                    color: "#eea849",
                    fontWeight: "700",
                    textDecoration: "underline",
                  }}
                >
                  {t("common.register")}
                </Button>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 wow fadeInUp"
            style={{marginBottom: "24px"}}
            data-wow-delay="400ms"
          >
            <div
              className="tournament__box-wrap active"
              style={{ margin: "auto" }}
            >
              <svg
                className="price-bg"
                viewBox="0 0 166 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.00792892 55V11C0.00792892 11 -0.729072 0.988 12.0079 1C24.7449 1.012 160.008 0 160.008 0C160.008 0 172.491 1.863 161.008 10C148.995 18.512 115.008 48 115.008 48C115.008 48 110.021 55.238 90.0079 55C69.9949 54.762 0.00792892 55 0.00792892 55Z"
                  fill="currentcolor"
                />
              </svg>

              <div className="tournament__box-price">
                <span>{t("common.apr")}</span>
                <span>{toggleBilled ? pools[1].apr : pools[4].apr}%</span>
              </div>

              <div
                className="tournament__box-caption"
                style={{ marginTop: "50px" }}
              >
                <span className="sub">
                  {t("vault_pricing.standard")} - {toggleBilled ? 3 : 6} {t("common.months")}
                </span>
                <h4 className="title">
                  {toggleBilled ? pools[1].apr_month : pools[4].apr_month}%{" "}
                  {t("common.per_month")}
                </h4>
              </div>

              <div
                style={{
                  margin: "auto",
                  marginTop: "32px",
                  textAlign: "center",
                }}
              >
                <ul className="tournament__box-list list-wrap">
                  <li>
                    <div className="tournament__box-list-item">
                      <h6 className="tournament__player-name">
                        {t("vault_pricing.maximum_investment")}
                      </h6>
                      <span className="tournament__player-price">
                        {toggleBilled ? pools[1].maximum : pools[4].maximum}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="tournament__box-list-item">
                      <h6 className="tournament__player-name">
                        {t("vault_pricing.daily_interest")}
                      </h6>
                      <span className="tournament__player-price">
                        {toggleBilled ? pools[1].daily : pools[4].daily}
                      </span>
                    </div>
                  </li>
                </ul>
                <Button
                  className="thm-btn thm-btn--dark"
                  style={{
                    marginTop: "16px",
                    color: "#eea849",
                    fontWeight: "700",
                    textDecoration: "underline",
                  }}
                >
                  {t("common.register")}
                </Button>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 wow fadeInRight"
            data-wow-delay="400ms"
          >
            <div
              className="tournament__box-wrap active"
              style={{ margin: "auto" }}
            >
              <svg
                className="price-bg"
                viewBox="0 0 166 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.00792892 55V11C0.00792892 11 -0.729072 0.988 12.0079 1C24.7449 1.012 160.008 0 160.008 0C160.008 0 172.491 1.863 161.008 10C148.995 18.512 115.008 48 115.008 48C115.008 48 110.021 55.238 90.0079 55C69.9949 54.762 0.00792892 55 0.00792892 55Z"
                  fill="currentcolor"
                />
              </svg>

              <div className="tournament__box-price">
                <span>{t("common.apr")}</span>
                <span>{toggleBilled ? pools[2].apr : pools[5].apr}%</span>
              </div>

              <div
                className="tournament__box-caption"
                style={{ marginTop: "50px" }}
              >
                <span className="sub">
                  {t("vault_pricing.premium")} - {toggleBilled ? 3 : 6} {t("common.months")}
                </span>
                <h4 className="title">
                  {toggleBilled ? pools[2].apr_month : pools[5].apr_month}%{" "}
                  {t("common.per_month")}
                </h4>
              </div>

              <div
                style={{
                  margin: "auto",
                  marginTop: "32px",
                  textAlign: "center",
                }}
              >
                <ul className="tournament__box-list list-wrap">
                  <li>
                    <div className="tournament__box-list-item">
                      <h6 className="tournament__player-name">
                        {t("vault_pricing.maximum_investment")}
                      </h6>
                      <span className="tournament__player-price">
                        {toggleBilled ? pools[2].maximum : pools[5].maximum}
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="tournament__box-list-item">
                      <h6 className="tournament__player-name">
                        {t("vault_pricing.daily_interest")}
                      </h6>
                      <span className="tournament__player-price">
                        {toggleBilled ? pools[2].daily : pools[5].daily}
                      </span>
                    </div>
                  </li>
                </ul>
                <Button
                  className="thm-btn thm-btn--dark"
                  style={{
                    marginTop: "16px",
                    color: "#eea849",
                    fontWeight: "700",
                    textDecoration: "underline",
                  }}
                >
                  {t("common.register")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="faq__sec-shape">
        <div className="shape shape--1">
          <img src="assets/img/shape/s_shape1.png" alt="" />
        </div>
        <div className="shape shape--2">
          <img src="assets/img/shape/s_shape2.png" alt="" />
        </div>
      </div>
    </section>
  );
};

export default Pricing;
