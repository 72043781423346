import React from "react";
import { useTranslation } from "react-i18next";

const Roadmap = () => {
  const { t } = useTranslation();

  return (
    <section
      id="roadmap"
      className="roadmap pt-145 pt-md-100 pb-300 pb-md-150"
      style={{ overflow: "hidden" }}
    >
      <div className="container">
        <div
          className="sec-title text-center mb-60  wow fadeInUp"
          data-wow-delay="100ms"
        >
          <h5 className="sec-title__subtitle">{t("roadmap.title")}</h5>
          <h2 className="sec-title__title">{t("roadmap.subtitle")}</h2>
        </div>
        <div className="roadmap__wrap ul_li_between wow fadeInUp" data-wow-delay="200ms">
          <div className="roadmap__item">
            <div className="roadmap__head ul_li">
              <div className="icon">
                <img src="assets/img/icon/rm_01.svg" alt="" />
              </div>
              <h3>{t("roadmap.phase_1.title")}</h3>
            </div>
            <ul className="roadmap__info list-unstyled">
              <li>{t("roadmap.phase_1.plan_1")}</li>
              <li>{t("roadmap.phase_1.plan_2")}</li>
              <li>{t("roadmap.phase_1.plan_3")}</li>
              <li>{t("roadmap.phase_1.plan_4")}</li>
            </ul>
            <span className="number">
              Q2 <br /> 2024
            </span>
          </div>
          <div className="roadmap__item">
            <div className="roadmap__head ul_li">
              <div className="icon">
                <img src="assets/img/icon/rm_02.svg" alt="" />
              </div>
              <h3>{t("roadmap.phase_2.title")}</h3>
            </div>
            <ul className="roadmap__info list-unstyled">
            <li>{t("roadmap.phase_2.plan_1")}</li>
              <li>{t("roadmap.phase_2.plan_2")}</li>
              <li>{t("roadmap.phase_2.plan_3")}</li>
              <li>{t("roadmap.phase_2.plan_4")}</li>
            </ul>
            <span className="number">
              Q3 <br /> 2024
            </span>
          </div>
          <div className="roadmap__item">
            <div className="roadmap__head ul_li">
              <div className="icon">
                <img src="assets/img/icon/rm_03.svg" alt="" />
              </div>
              <h3>{t("roadmap.phase_3.title")}</h3>
            </div>
            <ul className="roadmap__info list-unstyled">
            <li>{t("roadmap.phase_3.plan_1")}</li>
              <li>{t("roadmap.phase_3.plan_2")}</li>
              <li>{t("roadmap.phase_3.plan_3")}</li>
              <li>{t("roadmap.phase_3.plan_4")}</li>
            </ul>
            <span className="number">
              Q4 <br /> 2024
            </span>
          </div>
          <div className="roadmap__item">
            <div className="roadmap__head ul_li">
              <div className="icon">
                <img src="assets/img/icon/rm_02.svg" alt="" />
              </div>
              <h3>{t("roadmap.phase_4.title")}</h3>
            </div>
            <ul className="roadmap__info list-unstyled">
            <li>{t("roadmap.phase_4.plan_1")}</li>
              <li>{t("roadmap.phase_4.plan_2")}</li>
              <li>{t("roadmap.phase_4.plan_3")}</li>
              <li>{t("roadmap.phase_4.plan_4")}</li>
            </ul>
            <span className="number">
              Q1 <br /> 2025
            </span>
          </div>
          <div className="roadmap__item">
            <div className="roadmap__head ul_li">
              <div className="icon">
                <img src="assets/img/icon/rm_01.svg" alt="" />
              </div>
              <h3>{t("roadmap.phase_5.title")}</h3>
            </div>
            <ul className="roadmap__info list-unstyled">
            <li>{t("roadmap.phase_5.plan_1")}</li>
              <li>{t("roadmap.phase_5.plan_2")}</li>
              <li>{t("roadmap.phase_5.plan_3")}</li>
              <li>{t("roadmap.phase_5.plan_4")}</li>
            </ul>
            <span className="number">
              Q2 <br /> 2025
            </span>
          </div>
          <div className="roadmap__shape">
            <img src="assets/img/shape/rm_shape.png" alt="" />
          </div>
          <div className="roadmap__year">
            <h3 className="year year--start">2024</h3>
            <h3 className="year year--end">2025</h3>
          </div>
        </div>
      </div>
      <div className="roadmap__sec-shape">
        <div className="shape shape--1">
          <img src="assets/img/shape/s_shape1.png" alt="" />
        </div>
        <div className="shape shape--2">
          <img src="assets/img/shape/s_shape2.png" alt="" />
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
