import React from "react";
import { useTranslation } from "react-i18next";

const ChooseToken = () => {
  const { t } = useTranslation();

  return (
    <section className="features pos-rel pb-170 pb-md-100" style={{ overflow: "hidden" }}>
      <div className="container">
        <div
          className="sec-title text-center mb-95 wow fadeInUp"
          data-wow-delay="100ms"
        >
          <h5 className="sec-title__subtitle">{t("why_choose_us.title")}</h5>
          <h2 className="sec-title__title mb-25">
            {t("why_choose_us.subtitle")}
          </h2>
        </div>
        <div
          className="feature__wrap pos-rel ul_li_between wow fadeInUp"
          data-wow-delay="200ms"
        >
          <div className="feature__item text-center">
            <div className="icon">
              <img src="assets/img/icon/f_01.svg" alt="" />
            </div>
            <h4>
              {t("why_choose_us.service_1")} <br />{" "}
              {t("why_choose_us.sub_service_1")}
            </h4>
          </div>
          <div className="feature__item text-center">
            <div className="icon">
              <img src="assets/img/icon/f_02.svg" alt="" />
            </div>
            <h4>
              {t("why_choose_us.service_2")} <br />{" "}
              {t("why_choose_us.sub_service_2")}
            </h4>
          </div>
          <div className="feature__item text-center">
            <div className="icon">
              <img src="assets/img/icon/f_03.svg" alt="" />
            </div>
            <h4>
              {t("why_choose_us.service_3")} <br />{" "}
              {t("why_choose_us.sub_service_3")}
            </h4>
          </div>
          <div className="feature__item text-center">
            <div className="icon">
              <img src="assets/img/icon/f_04.svg" alt="" />
            </div>
            <h4>
              {t("why_choose_us.service_4")} <br />{" "}
              {t("why_choose_us.sub_service_4")}
            </h4>
          </div>
          <div className="feature__item text-center">
            <div className="icon">
              <img src="assets/img/icon/f_05.svg" alt="" />
            </div>
            <h4>
              {t("why_choose_us.service_5")} <br />{" "}
              {t("why_choose_us.sub_service_5")}
            </h4>
          </div>
          <div className="feature__line-shape">
            <img src="assets/img/shape/f_shape.png" alt="" />
          </div>
        </div>
      </div>
      <div className="feature__sec-shape">
        <img src="assets/img/shape/s_shape1.png" alt="" />
      </div>
    </section>
  );
};
export default ChooseToken;
