import React from 'react';
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  
  return (
    <section className="ico-contact pos-rel">
      <div className="container">
        <div className="ico-contact__wrap">
          <h2 className="title wow fadeInUp" data-wow-delay="100ms">{t('contact.title')}</h2>
          <form action="#!" className="wow fadeInUp" data-wow-delay="200ms">
            <div className="row">
              <div className="col-lg-6">
                <input type="text" placeholder={t('contact.enter_name')} />
              </div>
              <div className="col-lg-6">
                <input type="email" placeholder={t('contact.enter_email')} />
              </div>
              <div className="col-lg-12">
                <textarea
                  name="message"
                  id="message"
                  cols="30"
                  rows="10"
                  placeholder={t('contact.enter_your_message')}
                ></textarea>
              </div>
              <div className="ico-contact__btn text-center mt-10">
                <button className="thm-btn" type="submit">
                {t('contact.send_message')}
                </button>
              </div>
            </div>
          </form>
          <div className="ico-contact__shape-img">
            <div className="shape shape--1">
              <div data-parallax='{"y" : -50}'>
                <img src="assets/img/shape/c_shape1.png" alt="" />
              </div>
            </div>
            <div className="shape shape--2">
              <div data-parallax='{"y" : 60}'>
                <img src="assets/img/shape/c_shape2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ico-contact__shape">
        <div className="shape shape--1">
          <img src="assets/img/shape/f_shape1.png" alt="" />
        </div>
        <div className="shape shape--3">
          <img src="assets/img/shape/f_shape3.png" alt="" />
        </div>
      </div>
    </section>
  );
};
export default Contact;
