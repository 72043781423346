import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppRoutesEnum from '../../constants/route';
import Layout from '../../components/layout';
const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Layout showHeader={true} showFooter={false}>
      <div className="not-found-container">
        <h1>404</h1>
        <p>The page you are looking for does not exist</p>
        <button className="back-home-btn" onClick={() => navigate(AppRoutesEnum.HOME)}>
          Back to Home
        </button>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
