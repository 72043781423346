import React from 'react';
import Header from './header';
import Footer from './footer';
import { ToastContainer } from 'react-toastify';

const Layout = ({ children, showHeader = true, showFooter = true }) => {
  return (
    <div>
      <div className="progress-wrap">
        <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>

      {showHeader && <Header />}

      <ToastContainer />
      <main>{children}</main>
      {showFooter && <Footer />}
    </div>
  );
};

export default Layout;
